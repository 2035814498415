/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Col, Card, Row } from "react-bootstrap";
import Text from "../../../components/Text";
import MapdotPopup from "../MapdotPopup";

class MapVenuList extends Component {
  render() {
    const { mapdots, currentCity } = this.props;

    return (
      <Container fluid className="py-1">
        <Row className="justify-content-center">
          <Col xs={12} md={11} lg={10}>
            <Card style={{ width: "100%", height: "100vh", overflowY: "auto" }}>
              <Card.Body>
                <div
                  className="container"
                  style={{
                    height: "100%",
                    overflowY: "scroll", // Activa el scroll
                    paddingRight: "10px", // Evita el desbordamiento del scroll
                  }}
                >
                  <Row
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)", // 2 tarjetas por fila
                      gap: "10px",
                      // Ajustamos para la vista móvil
                      width: "100%",
                    }}
                  >
                    {/** 🔹 Ordenamos los mapdots para mostrar primero la ciudad actual */}
                    {[...mapdots]
                      .sort((a, b) => {
                        if (a.city === currentCity && b.city !== currentCity)
                          return -1;
                        if (a.city !== currentCity && b.city === currentCity)
                          return 1;
                        return a.city.localeCompare(b.city); // Orden alfabético para el resto
                      })
                      .map((mapdot, index) => (
                        <Col
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <Card style={{ width: "100%" }}>
                            {" "}
                            {/* Cambiado para 100% en móviles */}
                            <Card.Body>
                              <Row>
                                <Col md={2}>
                                  <i
                                    className="fa-solid fa-circle-info"
                                    style={{
                                      fontSize: "20px",
                                      color: "#4e6ac5",
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <h5 style={{ fontFamily: "Montserrat" }}>
                                    <MapdotPopup mapdot={mapdot} />
                                  </h5>
                                </Col>
                              </Row>

                              <hr
                                style={{
                                  margin: "0 0 5px 0",
                                  border: "1px solid #ccc",
                                }}
                              />

                              <div
                                style={{
                                  fontFamily: "Montserrat",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <span>{mapdot.address}</span>
                              </div>
                              <br />
                              <div
                                style={{
                                  fontFamily: "Montserrat",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <span>{mapdot.city}</span>
                              </div>
                              <br />

                              <div>
                                <a
                                  href={
                                    mapdot.coordinatesLatLong.Latitude
                                      ? `https://maps.google.com?q=${mapdot.coordinatesLatLong.Latitude},${mapdot.coordinatesLatLong.Longitude}`
                                      : `https://maps.google.com?q=${mapdot.coordinatesLatLong.lat},${mapdot.coordinatesLatLong.lng}`
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Row>
                                    <Col md={2}>
                                      <i
                                        className="fa-solid fa-directions"
                                        style={{
                                          fontSize: "20px",
                                          color: "#4e6ac5",
                                        }}
                                      />
                                    </Col>
                                    <Col xl={15} md={9}>
                                      <Text tid="goGoogle" />
                                    </Col>
                                  </Row>
                                </a>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(MapVenuList);
